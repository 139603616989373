import { render, staticRenderFns } from "./DentalQuote.vue?vue&type=template&id=29077c1f&scoped=true"
import script from "./DentalQuote.vue?vue&type=script&lang=js"
export * from "./DentalQuote.vue?vue&type=script&lang=js"
import style0 from "./DentalQuote.vue?vue&type=style&index=0&id=29077c1f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29077c1f",
  null
  
)

/* custom blocks */
import block0 from "@/locales/dental_quote.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fuseful%2Fdental-quote%2FDentalQuote.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fuseful%2Fdental-quote%2FDentalQuote.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports