<i18n src="@/locales/dental_quote.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="dental-quote">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          <span v-html="$t('article_p1')" />
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{ $t('article_p2_1') }}
        </p>
        <p>
          {{ $t('article_p2_2') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <p>
          {{ $t('article_p3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'DentalQuote',
    components: {
      ImanMoreInfo,
      ImanArticleSection,
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data () {
      return {
        articlesCardContentId: [2245, 224, 611, 83],
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_5.2.png')
      }
    },
  }
</script>

<style scoped lang="scss">
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/useful/dental-quote/h1-devis-dentaire-d.jpg");
      background-size: cover;
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/useful/dental-quote/h1-devis-dentaire-d.jpg");
      background-size: cover;
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../../../assets/img/useful/dental-quote/h1-devis-dentaire-m.jpg");
      background-size: cover;
    }
  }
</style>
